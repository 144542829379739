.main_container {
    position: relative;
    padding-left: 14% !important;
    padding-right: 12% !important;
    height: max-content;
}
.first_container{
    display: flex;
    justify-content: center;
    margin-top: -35px;
}
.MobilisightNav {
    padding: 10px 20px;
    display: flex;
    align-items: center;
    border-radius: 20px;
    border-color: #00000021;
    transition: background-color 0.3s ease;
    text-decoration: none;
    color: #000000;
    /* default text color */
    text-align: start;
    background-color: #00000005;
    /* default background color */
    cursor: pointer;
    box-shadow: inset 0px -1px 1px rgba(23, 22, 22, 0.39);
    /* Shadow effect */
    transition: background-color 0.1s ease, border-color 0.1s ease, box-shadow 0.1s ease;
    /* Added box-shadow transition */
    height: 86px;
    margin-bottom: 20px;
  }
  .title{
      padding-left: 55px;
      font-size: 25px;
      font-family: Montserrat;
      font-weight: 400;
  }