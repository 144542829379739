.brand-card img {
    width: 131px;
    height: 134px;
    border: 1px;
}

.brand-card .card-header {
    display: inline-flex;
    height: 173px;
    /* padding: 20px 19px 19px 176px; */
    justify-content: flex-end;
    align-items: center;
    flex-shrink: 0;
    background: #1E306E;
}
.brand-card .card-body p{
    color: black;
font-size: 1rem;
font-family: Inter;
font-weight: 400;
text-align: justify;
word-wrap: break-word
}
.brand-card .card-body {
    background: #D9D9D9;
}
.brand-card .card-body .btn {
    border-radius: 0px;
    font-size: large;
font-family: Montserrat;
font-weight: 600;
}
.brand-card .card-body .more-details p{
    color: white;
    font-family: Montserrat;
    font-size: 25px;
    font-weight: 300;
    word-wrap: break-word
}
.brand-card .card-body h2{
color: black;
font-size: xx-large;
font-family: Montserrat;
font-weight: 600;
word-wrap: break-word;
overflow-wrap: break-word;
}


.brand-card .card-body button {
    border-radius: 0px;
    
}

.brand-card .card {
    margin-left: 0px;
}