.banner img {
    width: 100%;
    height: auto;
    max-height: 350px;
    object-fit: cover;
    object-position: top;
}
.banner div {
    position: absolute;
    right: 35px;
}
.banner div h1 {
    position: relative;
    top: -100px;
    right: 3%;
    text-align: right;
    color: #FFF;
    font-family: Montserrat;
    font-size: auto;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}
.headings1 p {
    /* font-size: 25px; */
    color: #000;
    font-family: Montserrat;
    font-size: 25px;
    font-style: normal;
    font-weight: 200;
    line-height: normal;
}
.headings1 h1 {
    color: #000;
    font-family: Montserrat;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.headings1 {
    padding: 5%;
}
.MuiTabs-flexContainer .Mui-selected {
    color: #A2202C;
    font-size: auto;
    font-family: Montserrat;
    font-weight: 600;
    word-wrap: break-word;
}

.MuiTabs-flexContainer button {
color: black;
 font-size: auto;
 font-family: Montserrat;
 font-weight: 400;
 word-wrap: break-word
}
@media (max-width: 1200px) {
    .MuiTabs-flexContainer .Mui-selected {
    color: #A2202C;
    font-size: 18px;
    font-family: Montserrat;
    font-weight: 200;
    word-wrap: break-word;
}

.MuiTabs-flexContainer button {
color: black;
 font-size: 17px;
 font-family: Montserrat;
 font-weight: 100;
 word-wrap: break-word
}
}

body {
    font-family: Montserrat;
}