.Register-page {
    font-family: "Montserrat-SemiBold", Helvetica;
    font-size: 20px;
    margin: 0;
    padding: 0;
    background-image: url('/public/images/login_bg.png');
    min-height: 100vh;
    min-width: 100%;
    background-size:cover;
    background-position: center;
    background-repeat: no-repeat;
    color: white;
}
.Register-container {
    margin-top: 8vh;
    max-width: 80vh;
    background-color: rgba(255, 255, 255, 0.3);   
    padding:3vh;
}
.signin-button {
    border: 4px solid white; 
    background-color: transparent;
    border-radius: 0% !important;
    color: white;
    width: 20vw;
    height: 6vh;
    margin: auto;
    display: flex;
    /* flex-direction: column; */
    flex-wrap: wrap;
    justify-content: center;
    align-items: stretch;
    align-content: center;
}
input {
    border-radius: 0;
    /* transition: border-color 0.2s ease-in-out ; */
    padding: 8px !important;
    border: 1px solid #ccc !important;
    transition: border-color 0.2s ease-in-out;
    font-size: 0.93rem;
    line-height: 1.5;
}
.invalid-feedback{
    font-size: 13px;
    color: #ffffff;
}
/* style.css */

/* form-control.small-input input {
padding: 8px;
border: 1px solid #ccc;
transition: border-color 0.2s ease-in-out;
min-height: 100px;
font-size: 14px;
line-height: 1.5;
} */
  
/* form-control.small-input textarea:hover,
form-control.small-input textarea:focus {
border-color: #007bff;
}  */
  
textarea {
padding: 11px !important;
border: 1px solid #ccc !important;
transition: border-color 0.2s ease-in-out;
min-height: 60px !important;
font-size: 14px;
line-height: 1.5;
}

textarea:hover,
textarea:focus {
border-color: #007bff;
}
  