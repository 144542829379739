
.toggle-button {
  background-color: #333;
  color: #fff;
  border: none;
  padding: 5px;
  width: fit-content;
  cursor: pointer;
  display: none; /* Hidden by default on larger screens */
}

/* Media query for smaller screens */
@media (max-width: 768px) {
  .sidebar {
    display: none; /* Hide the sidebar on small screens */
  }

  .toggle-button {
    display: block; /* Show the toggle button on small screens */
    position: fixed;
    width: fit-content;
    background: #ffffff00;
    top: 10px;
    left: 10px;
  }

  .collapsed {
    width: 60px; /* Keep the sidebar collapsed on small screens */
  }
}
.nav-link {
  color: gray
}
.nav-item{
  border-bottom-style:inset;
  border-bottom-width: 0.01px;
}

.nav-item:hover {
  transform: scale(1.05); /* Increase the size by 10% on hover */
  transition: transform 0.3s; /* Add a smooth transition effect */
  background-color: rgba(128, 128, 128, 0.204);
}
.accordion-body {
  padding: 0px;
}