.explore {
    font-family: "Montserrat";
}
.explore .banner-explore img {
    width: 100%;
    height: 25vh;
}
.explore .banner-explore div {
    position: relative;
    background: #1e306e;
    margin-left: 8%;
    margin-right: 8%;
    bottom: 30px;
    height: 110px;
}

.explore .banner-explore div h1 {
    padding-left: 7%;
    color: white;
    color: #ffffff;
    font-weight: 800;
}

.explore .container-line {
    width:100%;
    border-bottom: 0.4vh solid #5C5C5C;
}

.explore .img-container {
    position:relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 22px !important;
}
.explore .img-container img {
    display: block;
    width:100%;
    height: 93px;
    border-radius: 20px;
    filter: brightness(90%);
    object-fit: cover;
}

.explore .overlay-content {
    position: absolute;
    top:50%;
    transform: translateY(-50%);
    padding: 4vw;
    color: white;
    font-weight: 800;
    font-size: 4vh;
}

.explore .left {
    left:0
}

.explore .right {
    right: 0;
}

/* .explore .img-config {
    display: inline-block;
    width:100%;
    height: 50vh;
    object-fit: cover;
    border-radius: 20px;
    filter: brightness(70%) contrast(80%);
} */

/* .explore .img-content { */
    /* position: absolute; */
    /* display: grid; */
    /* grid-template-columns: 80% 20%; */
    /* top:40%;
    bottom: 50%;
    transform: translate(-40%, -50%);
    color: white;
    padding-left: 40%;
    font-weight: 800;
    font-size: 5vh; */
    /* text-wrap:nowrap; */
/* } */


.explore .current-portal-img-container {
    
    width:100%;
    height:75px;
    background: linear-gradient(90deg,#D8D8D8,white,#D8D8D8);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: auto;
    box-sizing: border-box;
    box-shadow: 0px 4px 8px rgb(0,0,0,0.5);
    border-radius: 2vh;
}

.explore .current-portal-img-container:hover {
    transform: scale(1.1);
    transition: transform 0.3s ease;
}

.explore .current-portal-img-config {
    width: auto;
    height: 50px;
    position: absolute;
    object-fit: cover;
}

