.profile li {
    display: flex;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;}

.profile p {
    font-size: 1.2em;
    font-weight: 400;
}
