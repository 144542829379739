.div-flex-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  p {
  font-size: 0.85em; /* Scale down paragraphs to 85% of the base font size */
}

h3, h4, h5, h6 {
  font-size: 0.8em; /* Scale down headings to 80% of the base font size */
}
td {
  font-size: 80%;
}
label {
  font-size: 80%;
}
.small-circle-button {
  width: 30px; /* Set your desired width for the button */
  height: 30px; /* Set your desired height for the button */
  border-radius: 50%; /* Make the button round */
  background-color: #007bff; /* Set your desired button color */
  color: #fff; /* Set button text color */
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.small-circle-button:focus {
  outline: none;
}

/* Optional hover effect */
.small-circle-button:hover {
  background-color: #0056b3; /* Change the button color on hover */
}
b {
  font-size: x-small;
}
 input {
  height: 80%;
 }