.main{
    display: flex;
    background: #243782;
    flex-direction: column;
    max-height: 100%;
}
.rectangle{
        width: 50%;
        position: absolute;
        left: 27%;
        top: 14%;
        height: 19%
}
.nameLogo{
    width: 39%;
    position: absolute;
    left: 32.5%;
    top: 15%
}
.devPortal{
    width: 29%;
    position: absolute;
    left: 38%;
    top: 25%
  }
.idea{
    padding: 17px;
    padding-right: 80px;
    width: 311px;
    height: 250px 
}
.secondDiv{
    padding-bottom: 48px
}
.secondInternalDiv{
    display: flex;
    justify-content: center;
    margin-top: 18px;

}
.wordingsDiv{
    display: flex;
    flex-direction: column;
    padding-top: 58px;
    padding-bottom: 30px
}
.wordingsDiv1{
    font-family: Montserrat;
    font-weight: 600;
    color: #FFFFFF;
    font-size: 25px
}
.wordingsDiv2{
    font-family: Montserrat;
    font-weight: 300;
    color: #FFFFFF;
    font-size: 20px
}
.exploreButton{
    font-family: Montserrat;
    font-weight: 300;
    width: 40%;
    height: 38px;
    border: none;
    font-size: 18px;
    margin-top: 10px
  }
.midTextSection{
    font-family: Montserrat;
    font-weight: 600;
    color: #FFFFFF;
    font-size: 25px;
    text-align: center;
    letter-spacing: 1px;
  }

.flex-row-container{
    height: auto;
    display: flex;
    justify-content: space-evenly;
    padding-bottom: 3rem;
    padding-top: 20px;
}
.flex-box{
    width: 330px;
    height: 241px;
    background: linear-gradient(to right,#B7C2C5, #FFFFFF);
    border-radius: 10px; 
    text-align: justify;
    padding-left: 2rem;
}
.flex-content{
    font-family: Montserrat;
    position: absolute;
}
.flex-heading{
    font-weight: 500;
    font-size: 20px;
    font-weight: bold;
    padding-top: 20px;
    color: #4C4C4C
}
.flex-data{
    font-weight: 300;
    font-size: 14px;
    margin-top: -8px;
    text-align: justify;
    padding-right: 40px;
}
.containerClass{
    max-width: 1200px;
}

@media only screen and (min-width: 1024px) and (max-width: 1199px)  {
    .containerClass{
        max-width: 1199px;
    }
    .rectangle{
        top: 2%;
        height: 9%
    }
    .nameLogo{
        top: 3%
    }
    .devPortal{
        top: 7.5%
      }
    .flex-row-container{
        display: flex;
        justify-content: space-evenly;
        padding-bottom: 3rem;
        padding-top: 20px;
    }
    .flex-box{
        width: 300px;
        height: 241px;
        background: linear-gradient(to right,#B7C2C5, #FFFFFF);
        border-radius: 10px; 
        text-align: justify;
        padding-left: 2rem;
    }
    
}

@media only screen and (min-width: 1200px) and (max-width: 1799px) {
    .containerClass{
        max-width: 1799px;
    }
    .rectangle{
        top: 2%;
        height: 11%
    }
    .nameLogo{
        top: 3%
    }
    .devPortal{
        top: 8.5%
      }
    .flex-box{
        width: 360px;
        height: 200px;
        background: linear-gradient(to right,#B7C2C5, #FFFFFF);
        border-radius: 10px; 
        text-align: justify;
        padding-left: 2rem;
    }

}

@media only screen and (min-width: 1800px) and (max-width: 2000px){
    .containerClass{
        max-width: 2000px;
    }
    .rectangle{
        top: 2%;
        height: 15%
    }
    .nameLogo{
        top: 3%
    }
    .devPortal{
        top: 10.5%
      }
    .flex-box{
        width: 540px;
        height: 400px;
        background: linear-gradient(to right,#B7C2C5, #FFFFFF);
        border-radius: 20px; 
        text-align: justify;
        padding-left: 2rem;
    }
    .flex-heading{
        font-weight: 500;
        font-size: 40px;
        font-weight: bold;
        padding-top: 20px;
        color: #4C4C4C
    }
    .flex-data{
        font-weight: 300;
        font-size: 28px;
        margin-top: -8px;
        text-align:justify
    }
    .idea{
        padding: 23px;
        padding-right: 80px;
        width: 350px;
        height: 330px 
    }
    .secondDiv{
        padding-bottom: 55px
    }
    .wordingsDiv{
        display: flex;
        flex-direction: column;
        padding-top: 62px;
        padding-bottom: 30px
    }
    .wordingsDiv1{
        font-family: Montserrat;
        font-weight: 600;
        color: #FFFFFF;
        font-size: 40px
    }
    .wordingsDiv2{
        font-family: Montserrat;
        font-weight: 300;
        color: #FFFFFF;
        font-size: 30px
    }
    .exploreButton{
        font-family: Montserrat;
        font-weight: 300;
        width: 50%;
        height: 48px;
        border: none;
        font-size: 24px;
        margin-top: 10px
      }
      .midTextSection{
        font-size: 40px;
        letter-spacing: 3px;
      }
}
@media only screen and (min-width: 2001px) and (max-width: 2600px){
    .containerClass{
        max-width: 2600px;
    }
    .rectangle{
        top: 2%;
        height: 14%
    }
    .nameLogo{
        top: 3%
    }
    .devPortal{
        top: 10%;
        width: 28%
      }
    .flex-box{
        width: 703px;
        height: 370px;
        background: linear-gradient(to right,#B7C2C5, #FFFFFF);
        border-radius: 20px; 
        text-align: justify;
        padding-left: 2rem;
    }
    .flex-heading{
        font-weight: 500;
        font-size: 43px;
        font-weight: bold;
        padding-top: 20px;
        color: #4C4C4C
    }
    .flex-data{
        font-weight: 300;
        font-size: 30px;
        margin-top: -8px;
        text-align: justify;
        
    }
    .idea{
        padding: 23px;
        padding-right: 80px;
        width: 463px;
        height: 350px 
    }
    .secondDiv{
        padding-bottom: 55px
    }
    .wordingsDiv{
        display: flex;
        flex-direction: column;
        padding-top: 62px;
        padding-bottom: 30px
    }
    .wordingsDiv1{
        font-family: Montserrat;
        font-weight: 600;
        color: #FFFFFF;
        font-size: 50px
    }
    .wordingsDiv2{
        font-family: Montserrat;
        font-weight: 300;
        color: #FFFFFF;
        font-size: 32px
    }
    .exploreButton{
        font-family: Montserrat;
        font-weight: 300;
        width: 50%;
        height: 63px;
        border: none;
        font-size: 33px;
        margin-top: 10px
      }
      .midTextSection{
        font-size: 53px;
        letter-spacing: 3px;
      }
}