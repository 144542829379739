.page_box .box_container {
  position: relative;
  padding-left: 8%;
  padding-right: 8%;
  height: max-content;
}

.CatalogueDropdown {
  padding: 10px 20px;
  display: flex;
  align-items: center;
  border-radius: 20px;
  border-color: #00000021;
  transition: background-color 0.3s ease;
  text-decoration: none;
  color: #000000;
  /* default text color */
  text-align: start;
  background-color: #00000005;
  /* default background color */
  cursor: pointer;
  box-shadow: inset 0px -1px 1px rgba(23, 22, 22, 0.39);
  /* Shadow effect */
  transition: background-color 0.1s ease, border-color 0.1s ease, box-shadow 0.1s ease;
  /* Added box-shadow transition */
  height: 65px;
}
.OnboardDropdown {
  padding: 25px 20px;
  display: flex;
  align-items: center;
  border-radius: 20px;
  border-color: #00000021;
  transition: background-color 0.3s ease;
  text-decoration: none;  
  color: #000000;
  /* default text color */
  text-align: start;
  background-color: white;
  /* default background color */
  cursor: pointer;
  box-shadow: inset 0px -1px 1px rgba(23, 22, 22, 0.39);
  /* Shadow effect */
  transition: background-color 0.1s ease, border-color 0.1s ease, box-shadow 0.1s ease;
  /* Added box-shadow transition */
  height: 45px;
  width: 96%;
  margin-left: 14px;
}