.popover_class {
  position: absolute !important;
  top: 10px;
  margin-top: 10px;
  border: 1px solid black;
}
.popover_class::before {
  content: "";
  position: absolute;
  top: -20px;
  right: 5px;
  border-bottom: 10px solid black;
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
  border-top: 10px solid transparent;
  z-index: 10;
}

.popover_class:after {
  content: "";
  position: absolute;
  right: 4px;
  top: -5px;
  width: 0;
}
