.swaggerTemplate p {
    color: #00000082;
    font-family: "Montserrat";
    font-size: 18px;
    font-weight: 200;

}
.swaggerTemplate h2{
    color: #000000;
    font-family: "Montserrat";
    font-size: 30px;
    font-weight: 400;
}
