html,
body {
    background-color: #FFFFFF !important;
}

.plan .main-image {
    position: relative;
    width: 100%;
    height: 55vh;
    object-fit:cover;

}

.plan .overlay {
    position: absolute;
    width: 354px;
    height: 136px;
    left: 78px;
    top: 131px;
    background: rgba(36, 55, 130, 0.7);
}

.plan .overlay p{
    color:#FFFFFF;
    font-family: "Montserrat-ExtraBold", Helvetica;
    font-size: 40px;
    font-weight: 800;
    letter-spacing: 0;
    line-height: normal;
    padding-top: 38px;
    padding-left: 42px;
}

.plan .content-body {
    padding: 5%;
    position: relative;
    color: #000000 !important;
    font-family: "Montserrat-Regular", Helvetica;
    font-size: 40px;
    font-weight: 400;
}

.plan .sub-text {
    color: #000000;
    font-family: "Montserrat-ExtraLight", Helvetica;
    font-size: 25px;
    font-weight: 200;
    line-height: normal;
}


.plan .text-wrapper-1 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    color: white;
    font-size: 40px;
    font-family: 'Montserrat';
    font-weight: 800;
    word-wrap: break-word
}


.plan .text-wrapper-2 {
    color: white;
    font-size: 25px;
    font-family: 'Montserrat';
    font-weight: 300;
    word-wrap: break-word;
    width: 100%;
}

.plan .card-last-section {
    position: absolute;
    bottom: 0px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
}

.plan .plan_button {
    border: 2px solid white; 
    background-color: transparent;
    border-radius: 0% !important;
    color: white;
}
.plan .text-wrapper-3 {
    width: 100%;
    color: white;
    font-size: 40px;
    font-family: 'Montserrat';
    font-weight: 800;
    word-wrap: break-word
}

.plan .text-wrapper-4 {
    width: 100%;
    color: white;
    font-size: 30px;
    font-family: 'Montserrat';
    font-weight: 300;
    word-wrap: break-word
}

.plan .last-section {
    position: absolute;
    top:960px;
    word-wrap: break-word;
    font-family: 'Montserrat';
    color: 'black';
    width: 100%;
}

.plan .text-wrapper-5 {
    font-size: 40px;
    font-weight: 400;
}

.plan .text-wrapper-6 {
    font-size: 30px;
    font-weight: 200 !important;
}

.plan .nxt-image {
    width: 96px;
    height: 95px;
    float: right;
}


.plan .card{
    margin-top:10% ;
    width:100%;
    height: 586px;
    box-shadow: 0 6px 10px rgba(0,0,0,.08), 0 0 6px rgba(0,0,0,.05);
    transition: .3s transform cubic-bezier(.155,1.105,.295,1.12),.3s box-shadow,.3s -webkit-transform cubic-bezier(.155,1.105,.295,1.12);
    cursor: pointer;
}

.plan .card:hover{
    transform: scale(1.15);
}

.plan .card-1{
    background-color: #243782;
}

.plan .card-2{
    background-color: #186277;
}

.plan .card-3{
    background-color: #412283;
}

/* .MuiCardContent-root {
    width:100% !important;
    height:400px !important;
    overflow:hidden !important;
    top:220px !important
} */

.plan .plan-cards .container{
    top:100px;
    position: relative;
    z-index: 0 !important;
}

/* .MuiPaper-root {
    /* width: 60vh !important; */
    /* margin: 0 !important;
    padding: 0 !important;
    image-rendering: auto !important;
    transform: scale(1) !important; */

/* } */

/* class="MuiCardContent-root css-46bh2p-MuiCardContent-root" */
.MuiCardContent-root {
    width: 70vh;
    /* filter: blur(0) !important; */
 }  

.plan .carousel-gen {
    /* top:5vh; */
    position: relative;
    z-index: 0 !important;
}  

.plan .carousel-component {
    height: 500px;
    width:60%;
    margin:0 auto;
}  

.plan-card{
    top: 531px;
    /* padding: 5%; */
    /* position: relative; */
    color: #000000 !important;
    /* font-family: "Montserrat-Regular", Helvetica; */
    /* font-size: 40px; */
    /* font-weight: 400; */
    /* border: 1px solid #ccc;
    border-radius: 8px;
    padding: 16px;
    margin: 16px;
    max-width: 400px;
    width:100%;
    box-sizing: border-box; */
}

.plan-card-header{
    width: 100%;
    color: white;
    font-size: 40px;
    font-family: 'Montserrat';
    font-weight: 800;
    word-wrap: break-word
    
}

.plan-card-contents{
    color: white;
    font-size: 25px;
    font-family: 'Montserrat';
    font-weight: 300;
    word-wrap: break-word;
    width: 100%;
    
}

.plan .plan-center-align {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}
/* SMARTPHONES LANDSCAPE */
@media only screen and (min-width: 480px)  and (max-width: 767px) {

}

/* TABLETS PORTRAIT */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
    .plan .carousel-component {
        width:75%;
    } 
    .MuiCardContent-root {
        width: 70vh;
     } 
}


/* TABLET LANDSCAPE / DESKTOP */
@media only screen and (min-width: 1024px) and (max-width: 1199px) {
    .plan .carousel-component {
        width:60%;
    }
    .MuiCardContent-root {
        width: 80vh;
     } 

}   

@media only screen and (min-width: 1200px) and (max-width: 1439px) {
    .plan .carousel-component {
        width:50%;
    } 
    .MuiCardContent-root {
        width: 60vh;
     } 
}

@media only screen and (min-width: 1440px) and (max-width: 1799px) {
    .plan .carousel-component {
        width:48%;
    } 
    .MuiCardContent-root {
        width: 58vh;
     } 
}

@media only screen and (min-width: 1800px) {
    .plan .carousel-component {
        width:35%;
    } 
    .MuiCardContent-root {
        width: 50vh;
     } 

}
