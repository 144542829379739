.roundInput {
    border-radius: 30px;
    border-color: #000000a1;
    transition: background-color 0.3s ease;
    text-decoration: none;
    color: #000000;
    background-color: #00000005;
}

.roundDescription {
    border-radius: 15px;
    border-color: #000000a1;
    transition: background-color 0.3s ease;
    text-decoration: none;
    color: #000000;
    background-color: #00000005;
}

.contactUs input::placeholder { 
    color: #000000; /* Change this to your desired color */
}