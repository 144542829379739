.small-circle-button {
  width: 30px; 
  height: 30px; 
  border-radius: 50%; 
  background-color: #007bff; 
  color: #fff;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.small-circle-button:focus {
  outline: none;
}


.small-circle-button:hover {
  background-color: #0056b3; 
}
