
.video-container {
  position: absolute;
  z-index: -1;
  min-width: 100%;
  min-height: 100%;
  width: 100%;
  height: 100%;
}
.content {
  position: absolute;
  top: 0;
  min-width: 100%;
  overflow: scroll;
  width: 100%;
  height: 100%;
  left: 0;
}
.content .centerDiv {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

}
