.banner-remote-ops img {
    width: 100%;
    height: 158px;
    max-height: 350px;
    object-fit: fill;
    object-position: middle;
}

.banner-remote-ops .banner-div {
    position: relative;
    background: #1e306e;
    margin-left: 8%;
    margin-right: 8%;
    bottom: 30px;
    height: 95px;
    border-radius: 15px;
}
.banner-remote-ops div h1 {
    padding-left: 4% !important;
    color: white;
    color: #ffffff;
    font-family: "Montserrat";
    font-weight: 800;
}

.banner-remote-ops div p {
    padding-left: 4.5% !important;
    color: white;
    color: #ffffff;
    font-family: "Montserrat";
    font-weight: 200;
}
