
.information-container {
    display: none !important;
}
.scheme-container {
    display: none !important;
}
.operation-tag-content{
    text-align: justify !important;
}
.models {
    display: none !important;
} 
.swagger-ui {
    max-width: 100% !important;
    width: 100%;
}
.swagger-ui .wrapper {
    max-width: 100% !important;
    display: contents;
}