body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.swal2-popup {
  background: #ffffff !important;
  box-shadow: none !important;
  border: none !important;
}


html,
body {
    background-color: #FFFFFF !important;
}

.general {
    font-family: 'Montserrat';
    color: white;
}

.main-image {
    position: absolute;
    width: 100%;
    height: 85vh;
    object-fit:cover;
}

.overlay {
    position: absolute;
    width: 557px;
    height: 295px;
    left: 78px;
    top: 131px;
    background: rgba(36, 55, 130, 0.7);
}

.image-text {
    position: absolute;
    width: 547px;
    height: 132px;
    top: 30px;
    left: 10px;
    /* font-family: "Montserrat-ExtraBold", Helvetica; */
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 800;
    font-size: 40px;
    line-height: 49px;
    color: #FFFFFF;
    word-wrap: break-word;
}

.sub-image-text {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
}

.card {
    margin-left: 80px;
}

.gen-rect-box {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 23px;
    padding-bottom: 50px;
    position: absolute;
    width: 283px;
    height:fit-content;
    top: 500px;
    background: #D9D9D9 !important;
}

.below-cards {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2%;
    padding-bottom: 50px;
    width: 283px;
    top: 600px;
}

.below-cards-1{
    background: #1E306E !important;
}

.below-cards-2{
    background: #1E306E !important;
    padding-top: 5vh;
}

.below-cards-3{
    background: #1E306E !important;
    padding-top: 5vh;
}

.below-cards-4{
    background: #1E306E !important;
}

.rect4 {
    /* margin-top: 10%; */
    display: block;
    position: relative;
    left: 0px;
    top: 600px;
    background: #1E306E;
    max-width: 100% !important;
}

.label {
    height: 73px;
    width: 642px;
}

.text-wrapper {
    color: #ffffff;
    font-family: "Montserrat";
    font-size: 60px;
    font-weight: 800;
    text-align: center;
    line-height: normal;
    padding-top: 62px;
    padding-bottom: 62px;
}

.contact-us {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 23px;
}

.text-wrapper2 {
    margin: 10%;    
    text-align: center;
    height: 35px;
    width: 174px;
    color: #000000;
    font-family: "Montserrat";
    font-size: 30px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: normal;
}

.text-wrapper3 {
    margin: 4%;
    text-align: center;
    height: 211px;
    width: 231px;
    color: #000000;
    font-family: "Montserrat";
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: normal;
}

.center-vertical-col {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.below-content-header {
    color: #000000;
    font-size: 35px;
    font-weight: 400px;
    word-wrap: break-word;
    top: 600px;
}

.below-content {
    color: #000000;
    font-size: 20px;
    font-weight: 200px;
    word-wrap: break-word;
}

.logo-container {
    display:flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(90deg,#1E306E,#2944a6,#1E306E);
    max-width: 100% !important;
}

.logo {
    max-width: 18vh;
    height:auto;
    margin: 5vh;
    /* box-sizing: border-box; */
}

.logo:hover {
    transform: scale(1.1);
    transition: transform 0.3s ease;
}

/* SMARTPHONES PORTRAIT */
@media only screen and (min-width: 300px) and (max-width: 479px) {

    .main-heading {
        height: 18px;
    }
    
    .overlay {
        position: relative;
        width: 280px;
        height: 140px;
        left: 18px;
        top: 8px;
    }
    
    .image-text {
        width: 300px;
        top: 0px;
        left: 10px;
        font-size: 16px;
        line-height: 49px;
    }
    
    .sub-image-text {
        font-weight: 400;
        font-size: 8px;
        line-height: 24px;
    }
    
    .discover-btn {
        font-weight: 400;
        font-size:8px !important;
        width: 80px;
    }

    .discover-btn .btn .btn-light {
        border-radius: 0 !important;
    }

    .card {
        margin-left: 20%;
    }

    
    .gen-rect-box {
        padding-bottom: 7px;
        padding: 25px;
        width: 83px;
        top: 26px;
        margin-bottom: 10px;
    }
    
    .rect4 {
        left: 0px;
        top: 400px;
    }
    
    .label {
        height: 63px;
        width: 42px;
    }
    
    .text-wrapper {
        font-size: 45px;
        padding-top: 6px;
        padding-bottom: 62px;
    }
    
    .contact-us {
        padding: 20px;
    }
    
    .text-wrapper2 {
        margin-top: 4%;
        margin-left: 4%;
        height: 40px;
        width: 150px;
        font-size: 20px;
    }
    
    .text-wrapper3 {
        margin-left: 1%;
        width: 141px;
        font-size: 10px;
        /* /* left: 20px; */
    }
}

/* SMARTPHONES LANDSCAPE */
@media only screen and (min-width: 480px)  and (max-width: 767px) {

    .main-heading {
        height: 25px;
    }
    
    .overlay {
        position: relative;
        width: 390px;
        height: 150px;
        left: 38px;
        top: 50px;
    }
    
    .image-text {
        width: 390px;
        top: 0px;
        left: 10px;
        font-size: 20px;
        line-height: 49px;
    }
    
    .sub-image-text {
        font-weight: 400;
        font-size: 10px;
        line-height: 24px;
    }
    
    .discover-btn {
        font-weight: 400;
        font-size: 10px !important;
        width: 170px !important;
    }

    .card {
        margin-left: 20%;
    }

    
    .gen-rect-box {
        padding-bottom: 7px;
        padding: 25px;
        width: 83px !important;
        top: 70px;
        margin-bottom: 20px;
    }
    
    .rect4 {
        left: 0px;
        top: 400px;
    }
    
    .label {
        height: 63px;
        width: 42px;
    }
    
    .text-wrapper {
        font-size: 45px;
        padding-top: 62px;
        padding-bottom: 62px;
    }
    
    .contact-us {
        padding: 20px;
    }
    
    .text-wrapper2 {
        margin: 5%;
        height: 40px;
        width: 220px;
        font-size: 25px;
    }
    
    .text-wrapper3 {
        margin: 0%;
        width: 220px;
        font-size: 12px;
    }
}

/* TABLETS PORTRAIT */
@media only screen and (min-width: 768px) and (max-width: 1023px) {


    .logo {
        max-width: 12vh;
        margin: 3vh;
    }

    .nav-link {
        position: relative;
        z-index: 9000 !important;
      }
    .overlay {
        position: relative;
        width: 500px;
        height: 250px;
        left: 78px;
        top: 20px;
    }
    
    .image-text {
        width: 500px;
        height: 120px;
        top: 0px;
        left: 10px;
        font-size: 32px;
        line-height: 49px;
    }
    
    .sub-image-text {
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
    }
    
    .card {
        margin-left: 48px;
    }
    
    .gen-rect-box {
        padding-bottom: 50px;
        padding: 23px;
        width: 283px;
        top: 50px;
    }
    
    .rect4 {
        left: 0px;
        top: 480px;
    }
    
    .label {
        height: 63px;
        width: 42px;
    }
    
    .text-wrapper {
        font-size: 55px;
        padding-top: 62px;
        padding-bottom: 62px;
    }
    
    .contact-us {
        padding: 20px;
    }
    
    .text-wrapper2 {
        margin: 4%;
        height: 40px;
        width: 154px;
        font-size: 22px;
    }
    
    .text-wrapper3 {
        margin: -1%;
        width: 161px;
        font-size: 10px;
    }

}


/* TABLET LANDSCAPE / DESKTOP */
@media only screen and (min-width: 1024px) and (max-width: 1199px) {
   
    .overlay {
        width: 557px;
        height: 295px;
        left: 78px;
        top: 131px;
    }
    
    .image-text {
        width: 547px;
        height: 132px;
        top: 30px;
        left: 10px;
        font-size: 35px;
        line-height: 49px;
    }
    
    .sub-image-text {
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
    }
    
    .card {
        margin-left: 60px;
    }
    
    .gen-rect-box {
        padding-bottom: 50px;
        padding: 23px;
        width: 283px;
        top: 450px;
    }
    
    .rect4 {
        left: 0px;
        top: 600px;
    }
    
    .label {
        height: 73px;
        width: 642px;
    }
    
    .text-wrapper {
        font-size: 55px;
        padding-top: 62px;
        padding-bottom: 62px;
    }
    
    .contact-us {
        padding: 23px;
    }
    
    .text-wrapper2 {
        margin: 10%;
        height: 30px;
        width: 174px;
        font-size: 25px;
    }
    
    .text-wrapper3 {
        margin: -1%;
        width: 252px;
        font-size: 12px;
    }


}   


@media only screen and (min-width: 1200px) and (max-width: 1799px) {

    .overlay {
        width: 557px;
        height: 295px;
        left: 78px;
        top: 131px;
        background: rgba(36, 55, 130, 0.7);
    }
    
    .image-text {
        position: absolute;
        width: 547px;
        height: 132px;
        top: 30px;
        left: 10px;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 800;
        font-size: 40px;
        line-height: 49px;
        font-family: 'Montserrat';
        color: #FFFFFF;
        word-wrap: break-word;
    }
    
    .sub-image-text {
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
    }
    
    .card {
        margin-left: 80px;
    }
    
    .gen-rect-box {
        padding: 23px;
        padding-bottom: 50px;
        position: absolute;
        width: 283px;
        height:fit-content;
        top: 400px;
        background: #D9D9D9 !important;
    }
    
    .rect4 {
        display: block;
        position: relative;
        width: 100%;
        left: 0px;
        top: 600px;
        background: #1E306E;
    }
    
    .label {
        height: 73px;
        width: 642px;
    }
    
    .text-wrapper {
        color: #ffffff;
        font-family: "Montserrat-ExtraBold", Helvetica;
        font-size: 60px;
        font-weight: 800;
        text-align: center;
        line-height: normal;
        padding-top: 62px;
        padding-bottom: 62px;
    }
    
    .contact-us {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 23px;
    }
    
    .text-wrapper2 {
        margin: 10%;
        text-align: center;
        height: 35px;
        width: 174px;
        color: #000000;
        font-family: "Montserrat-SemiBold", Helvetica;
        font-size: 30px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: normal;
    }
    
    .text-wrapper3 {
        margin: 4%;
        text-align: center;
        height: 211px;
        width: 252px;
        color: #000000;
        font-family: "Montserrat-Light", Helvetica;
        font-size: 16px;
        font-weight: 300;
        letter-spacing: 0;
        line-height: normal;
    }
    
    

}

@media only screen and (min-width: 1800px) {
    .rect4 {
        margin-top: 80vh;
    }
    .text-wrapper2 {
        margin: 10%;
        height: 30px;
        width: 174px;
        font-size: 25px;
    }
    
    .text-wrapper3 {
        margin: -1%;
        width: 231px;
        font-size: 12px;
    }
    .gen-rect-box {
        padding: 23px;
        padding-bottom: 50px;
        position: absolute;
        width: 283px;
        height:fit-content;
        top: 800px;
        background: #D9D9D9 !important;
    }

    .below-cards {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 1%;
        /* padding-bottom: 50px; */
        /* position: absolute; */
        width: 283px;
        /* height:fit-content; */
        top: 1100px;
        /* background: red !important; */
    }

    .below-content-header {
        top: 1100px;
    }
    
    
}


@media screen and (max-width: 425px) and (max-height: 842px) {
    .main_div {
        height: 3700px !important;
    }
}
@media screen and (min-width: 426px) and (max-width: 768px) and (max-height: 842px) {
    .main_div {
        height: 2000px !important;
    }
}
@media screen and (min-width: 769px) and (max-width: 1440px) and (max-height: 842px) {
    .main_div {
        height: 1800px !important;
    }
}
@media screen and (min-width: 1441px) and (min-height: 843px) {
    .main_div {
        height: 2400px !important;
    }
}
.margin-footer {
    display: block;
    position: relative;
    margin-top: 100vh;
}