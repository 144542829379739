.login-page {
    font-family: "Montserrat-SemiBold", Helvetica;
    font-size: 20px;
    /* position: relative; */
    margin: 0;
    padding: 0;
    background-image: url('/public/images/login_bg.png');
    min-height: 100vh;
    min-width: 100%;
    background-size:cover;
    background-position: center;
    background-repeat: no-repeat;
}

.login-page .login-container{
    margin-top: 10vh;
    display: flex !important;
    flex-wrap: wrap;
    justify-content: center !important;
    align-items: center !important;
    height: 100%;
    font-family: 'Montserrat';
    color: white;
    background-color: rgba(255,255,255,0.3);
    max-width:30vw;
    flex-direction: column;
    text-align: center !important;
}
.login-page .Registration-container{
    margin-top: 10vh;
    display: flex !important;
    flex-wrap: nowrap;
    justify-content: center !important;
    align-items: center !important;
    height: 100%;
    font-family: 'Montserrat';
    color: white;
    background-color: rgba(255,255,255,0.3);
    flex-direction: column;
    text-align: center !important;
}
.Registration-container .row {
    flex-wrap: nowrap !important;
}

.login-page .center-align {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.login-page .signin-button {
    border: 4px solid white; 
    background-color: transparent;
    border-radius: 0% !important;
    color: white;
    width: 20vw;
    height: 6vh;
}

.login-page .form-group {
    width: 100% !important;
}

.login-page .form-control {
    border-radius: 0 !important;
}

.login-page .form-check-input {
    text-align: left !important;
}

.login-page .form-check-label {
    text-align: left !important;
}


@media only screen and (min-width: 300px) and (max-width: 768px) {
    .login-page {
        font-size: 12px !important;
    }
    .login-page .login-container {
        overflow-y:auto;
        max-width:60vw;
        min-height: 80vh;
    }
}
.password-container {
    position: relative;
  }
  
  .password-toggle-icon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
  }
  
  .form-control {
    padding-right: 2.5rem; /* Adjust this to make space for the icon */
  }
  