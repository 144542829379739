.documentation {
    padding-bottom: 30vh;
}

.documentation h1, .callback_api h1 {
    font-family:'Montserrat' ;
    font-size: 30px;
    font-weight: 300;
}
.documentation h2, .callback_api h2 {
    font-family:'Montserrat' ;
    font-size: 25px;
    font-weight: 300;
}

.documentation h3, .callback_api h3 {
    font-family:'Montserrat' ;
    font-size: 20px;
    font-weight: 300;
}
.documentation p, .callback_api p {
    font-size: 18px;
    font-weight: 100;
    padding-top: 2%;
    color: #000000b2;    ;
}

.documentation li, .callback_api li {
    font-size: 15px;
    font-weight: 100;
    color: #000000b2;
}

.bold {
    font-weight: 800;
}

.table-with-blue-header {
    border-collapse: collapse;
    margin-bottom: 0.5rem;
}

.table-with-blue-header tr {
    height: 3rem;
}

.table-with-blue-header td {
    width: 226pt;
    border-top-style: solid;
    border-top-width: 1pt;
    border-left-style: solid;
    border-left-width: 1pt;
    border-bottom-style: solid;
    border-bottom-width: 1pt;
    border-right-style: solid;
    border-right-width: 1pt;
    font-size: 15px;
    padding: 5px;
}

.table-with-blue-header th {
    background-color: #365F91 !important;
    color: white;
}

.table-container {
    overflow-y: auto;
    margin-bottom: 2rem;
    padding-right: 5px;
}


.documentation .p-no-padding {
    padding-top: 0;
}


.documentation .plain-table {
    border: solid 1px #000000;
    border-collapse: collapse;
    margin-left: 5.525pt;
    margin-bottom: 20px;
}


.documentation .plain-table th {
    font-weight: bold;
    height: 3rem;
}

.documentation .plain-table td {
    border: solid 1px #000000;
    padding: 5px;
    height: 3rem;
    font-size: 15px;
}

.documentation .no-style-list {
    list-style: none;
}

.documentation .no-style-list li::marker {
    color: transparent;
}

.documentation .no-style-list span{
    margin-right: 0.5rem;
}

.documentation .green-highlight {
    background-color: #0F0;
}

.documentation .yellow-highlight {
    background-color: #FF0;
}

.documentation .blue-cell {
    background-color: #365F91;
    color: white;
}

.documentation .black-text {
    color: black;
}

.documentation .link-text {
    color: rgb(10,88,202);
}

.mobilisight-tabs button {
    min-width: fit-content;
    font-size: 20px;
    padding: 12px 10px;
}

@media (max-width: 965px) {
    .mobilisight-tabs button { 
        font-size: 12px !important;
    }
}

img {
    max-width: -webkit-fill-available;
}

.table-container::-webkit-scrollbar {
    height: 7px;
    width: 4px;
    background-color: transparent;
}

.table-container::-webkit-scrollbar-thumb {
    background: #A9AAAA;
    border-radius: 25px;
}

.callback_api .bright-blue {
    background-color: #0094ff !important;
    color: #ffffff;
    text-align: end;
}

.callback_api .empty-cell {
    background-color: transparent !important;
}

.callback_api .dark-grey-row {
    background-color: #c1c1c1;
}

.callback_api .light-grey-row {
    background-color: #f0f0f0;
}

.callback_api .ProseMirror {
    -webkit-box-flex: 1;
    flex-grow: 1;
    box-sizing: border-box;
    outline: none;
    font-size: 16px;
    overflow-wrap: break-word;
    white-space: pre-wrap;
    max-width: 75%;
}

.callback_api .code-block--start {
    position: absolute;
    visibility: hidden;
    height: 1.5rem;
    top: 0px;
    left: 0px;
}

.callback_api .code-block-content-wrapper--fg {
    background-color: var(--ds-background-neutral, #F4F5F7);
    display: flex;
    border-radius: var(--ds-border-radius, 3px);
    width: 100%;
    counter-reset: line 0;
    background-image: linear-gradient(
    to right,
    var(--ds-background-neutral, #F4F5F7) var(--ds-space-300, 24px),
    transparent var(--ds-space-300, 24px)
  ),linear-gradient(
    to right,
    var(--ds-surface-raised, transparent) var(--ds-space-300, 24px),
    transparent var(--ds-space-300, 24px)
  ),linear-gradient(
    to left,
    var(--ds-background-neutral, #F4F5F7) var(--ds-space-100, 8px),
    transparent var(--ds-space-100, 8px)
  ),linear-gradient(
    to left,
    var(--ds-surface-raised, transparent) var(--ds-space-100, 8px),
    transparent var(--ds-space-100, 8px)
  ),linear-gradient(
    to left,
    var(--ds-shadow-overflow-spread, rgba(9, 30, 66, 0.13)) 0,
    var(--ds-UNSAFE-transparent, rgba(99, 114, 130, 0))  var(--ds-space-100, 8px)
  ),linear-gradient(
    to left,
    var(--ds-shadow-overflow-perimeter, transparent) 0,
    var(--ds-UNSAFE-transparent, transparent)  var(--ds-space-100, 8px)
  ),linear-gradient(
    to right,
    var(--ds-shadow-overflow-spread, rgba(9, 30, 66, 0.13)) 0,
    var(--ds-UNSAFE-transparent, rgba(99, 114, 130, 0))  var(--ds-space-100, 8px)
  ),linear-gradient(
    to right,
    var(--ds-shadow-overflow-perimeter, transparent) 0,
    var(--ds-UNSAFE-transparent, transparent)  var(--ds-space-100, 8px)
  );
    background-repeat: no-repeat;
    background-attachment: local, local, local, local, scroll, scroll, scroll, scroll;
    background-size: var(--ds-space-300, 24px) 100%,var(--ds-space-300, 24px) 100%,var(--ds-space-100, 8px) 100%,var(--ds-space-100, 8px) 100%,var(--ds-space-100, 8px) 100%,1px 100%,var(--ds-space-100, 8px) 100%,1px 100%;
    background-position: 0px 0px, 0px 0px, 100% 0px, 100% 0px, 100% 0px, 100% 0px, 0px 0px, 0px 0px;
}

.callback_api .line-number-gutter--fg {
    background-color: var(--ds-background-neutral, #EBECF0);
    position: relative;
    width: var(--lineNumberGutterWidth, 2rem);
    padding: 0px var(--ds-space-100, 8px);
    flex-shrink: 0;
    font-size: 0.875rem;
    box-sizing: content-box;
}

.callback_api .code-block-content-wrapper--fg > .code-content--fg {
    display: flex;
    flex: 1 1 0%;
}

.callback_api .code-block {
    overflow-x: auto;
    background-color: #F4F5F7;
}

.callback_api code {
    tab-size: 4;
    cursor: text;
    color: var(--ds-text, #172B4D);
    border-radius: var(--ds-border-radius, 3px);
    margin: var(--ds-space-100, 8px);
    font-size: 0.875rem;
    line-height: 1.5rem;
    text-wrap: nowrap;
    font-family: "SFMono-Medium","SF Mono","Segoe UI Mono","Roboto Mono","Ubuntu Mono",Menlo,Courier,monospace;
}

.callback_api .code-content__line-number--wrapped{
    pointer-events: none;
    user-select: none;
    width: var(--lineNumberGutterWidth, 2rem);
    left: 0px;
    position: absolute;
    font-size: 0.875rem;
    padding: 0px var(--ds-space-100, 8px);
    line-height: 1.5rem;
    text-align: right;
    color: var(--ds-text-subtlest, #505F79);
    box-sizing: content-box;

    display: block;
}

span[data-testid="selection-marker-selection"] {
    text-wrap: nowrap;
}

.callback_api .json-content{
    display: flex;
    justify-content: center;
}

.MuiTabs-flexContainer .Mui-disabled {
    color: #A9AAAA;
}