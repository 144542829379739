.vehicle-capability-tabs {
    margin-left: 8%;
    margin-right: 4%;
}

.Heading-vehicle-cap .ApiSpec {
    width: 100%;
    padding-bottom: 30px;
}

.Heading-vehicle-cap h2{
    color: #000;
    font-family: Montserrat;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.Heading-vehicle-cap p {
    /* font-size: 25px; */
    color: #000;
    font-family: Montserrat;
    font-size: 25px;
    font-style: normal;
    font-weight: 200;
    line-height: normal;
}
.inspired-section h2 {
    color: #000;
    font-family: Montserrat;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.inspired-section p {
   color: #000;
    font-family: Montserrat;
    font-size: 25px;
    font-style: normal;
    font-weight: 200;
    line-height: normal;
}

.banner-remote-ops div h1 {
    padding-left: 7%;
    color: white;
    color: #ffffff;
    font-family: "Montserrat";
    font-weight: 800;
}
.feature-content p {
    /* font-size: 25px; */
    color: #000;
    font-family: Montserrat;
    font-size: 25px;
    font-style: normal;
    font-weight: 200;
    line-height: normal;
}
.feature-content h1 {
    color: #000;
    font-family: Montserrat;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.feature-content {
    padding-left: 9%;
    padding-right: 9%;
    padding-top: 2%;


}
.feature-content .discription-image {
    height: auto;
    width: auto;
}
.feature-content .firstContainer h2 {
    color: #000000;
    font-family: "Montserrat";
    font-size: 30px;
    font-weight: 400;
}

.feature-content .firstContainer p{
    color: #00000082;
    font-family: "Montserrat";
    
    font-size: 18px;
    font-weight: 200;

}
.MuiTabs-flexContainer .Mui-selected {
    color: #A2202C;
    font-size: auto;
    font-family: Montserrat;
    font-weight: 600;
    word-wrap: break-word;
}

.MuiTabs-flexContainer button {
color: black;
 font-size: auto;
 font-family: Montserrat;
 font-weight: 400;
 word-wrap: break-word
}

body {
    font-family: Montserrat;
}
.try-all-api-section button {
    background: #1E306E;
    border-radius: 0;
    color: white;
    font-family: "Montserrat";
    font-weight: 300;
    font-size: 25px;
}
.try-all-api-section {
    margin-left: 20%;
    margin-right: 20%;
}

.api-spec-container p {
    color: #00000082;
    font-family: "Montserrat";
    font-size: 20PX;
    font-weight: 200;
}
.api-spec-container h1 {
    font-family: "Montserrat";
    font-size: 40px;
    font-weight: 400;
}
.api-spec-container hr {
    color: #000;
}
@media (max-width: 1200px) {
    .MuiTabs-flexContainer .Mui-selected {
    color: #A2202C;
    font-size: 16px;
    font-family: Montserrat;
    font-weight: 200;
}

.MuiTabs-flexContainer button {
color: black;
 font-size: 15px;
 font-family: Montserrat;
 font-weight: 100;
}
.try-all-api-section {
    margin-left: 0%;
    margin-right: 0%;
}
.feature-content {

    padding-top: 2%;


}
}
/* .information-container {
    display: none !important;
}
.scheme-container {
    display: none !important;
} */